<template>
  <div class="settings">
    <div v-if="isSave && !isBadWord" class="saved-settings">
      <span class="saved-text"> Settings saved successfully </span>
      <XIcon class="x-icon" @click="hidePopup" />
    </div>
    <h1>My Settings</h1>
    <div class="user-info">
      <span class="settings__uppercase"> my qr code </span>
      <!-- <QRCode class="settings__logo" /> -->
      <div ref="qrCode" class="settings__logo"></div>
      <span class="uuid"> {{ uuid }} </span>
    </div>
    <label class="form-header"
      >Email:
      <VWFormField
        class="form-field"
        :class="{ valid: email }"
        placeholder="email@email.com"
        type="email"
        disabled
        v-model="email"
    /></label>
    <label class="form-header"
      >Username:
      <VWFormField
        class="form-field"
        :class="{ valid: username && !isBadWord }"
        v-model="username"
        placeholder="Username"
        type="text"
        :error="isBadWord || (isError && !username)"
        :errorMessage="message"
    /></label>
    <label class="form-header"
      >Password:
      <VWFormField
        class="form-field"
        v-model="currentPass"
        :class="{ valid: currentPass }"
        placeholder="Current Password"
        type="password"
    /></label>
    <label class="form-header"
      ><VWFormField
        class="form-field"
        :class="{ valid: newPass && !mismatch }"
        v-model="newPass"
        :errorMessage="mismatch"
        placeholder="New Password"
        type="password"
    /></label>
    <label class="form-header"
      ><VWFormField
        class="form-field"
        :class="{ valid: confirmPass && !mismatch }"
        v-model="confirmPass"
        :errorMessage="mismatch"
        placeholder="Confirm Password"
        type="password"
    /></label>

    <div class="checkbox">
      <span class="communications"> Communications opt-in </span>
      <div class="label-holder">
        <PassportCheckbox
          v-model="optChecked"
          name="opt-in"
          label="Keep me in the loop - subscribe to marketing emails."
        />
      </div>
    </div>

    <PassportButton label="Save Changes" class="settings-button" @click="validateForm" />
  </div>
</template>

<script>
import { ApiService } from '@/services/api.service';
import PassportButton from '@/components/PassportButton.vue';
import VWFormField from '@/components/VWFormField.vue';
import XIcon from '@/assets/icons/x_icon.svg';
import PassportCheckbox from '@/components/PassportCheckbox.vue';
import QRCodeStyling from 'qr-code-styling';

const BadWords = require('bad-words');

const badWords = new BadWords();

export default {
  name: 'Login',
  components: {
    PassportButton,
    VWFormField,
    PassportCheckbox,
    XIcon,
  },
  data() {
    return {
      username: this.$store.state.username,
      uuid: this.$store.state.uuid,
      email: this.$store.state.email,
      optChecked: this.$store.state.optIn,
      isBadWord: false,
      message: '',
      isSave: false,
      isError: false,
      mismatch: '',
      currentPass: '',
      newPass: '',
      confirmPass: '',
      jwt: this.$store.state.jwt,
    };
  },
  mounted() {
    const qrCode = new QRCodeStyling({
      width: 150,
      height: 150,
      margin: 5,
      type: 'svg',
      data: this.uuid,
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 10,
      },
    });
    qrCode.append(this.$refs.qrCode);
  },
  methods: {
    async validateForm() {
      ApiService.setAuth(this.jwt);
      this.isError = true;
      if (badWords.isProfane(this.username)) {
        this.isBadWord = true;
        this.message = 'Username cannot contain profanity ';
      } else {
        this.isBadWord = false;
        this.message = '';
      }

      const userPreferences = {
        username: this.username,
        optIn: this.optChecked,
      };
      if (
        (this.username !== this.$store.state.username && this.username !== '') ||
        this.optChecked !== this.$store.state.optIn
      ) {
        await ApiService.patch(`/user/${this.uuid}`, userPreferences).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.$store.commit('setUsername', this.username);
            this.$store.commit('setOptIn', this.optChecked);
            this.isSave = true;
          }
        });
      }
      if (this.newPass !== this.confirmPass) {
        this.mismatch = 'Passwords do not match';
      } else {
        this.mismatch = '';
      }
      const profile = {
        oldPassword: this.currentPass,
        newPassword: this.newPass,
      };
      if (this.username && this.currentPass && this.newPass && this.confirmPass && !this.isBadWord && !this.mismatch) {
        this.isError = false;
        await ApiService.post('/user/change-password', profile).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.isSave = true;
          }
        });
      }
      setTimeout(() => {
        this.isSave = false;
      }, 3000);
    },
    hidePopup() {
      this.isSave = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 32px;

  &__logo {
    margin: 1em 0;
  }
  &__uppercase {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
}
.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uuid {
  margin-bottom: 2em;
  font-size: 8px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.settings-button {
  width: 15.625em;
  height: 3em;
  margin-top: 2em;
  margin-bottom: 2em;
  font-family: $head-font-stack;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
}
.form-header {
  margin-top: 0.5em;
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  @include bp-sm-phone-landscape {
    margin-top: 1em;
  }
}
.form-field {
  &.valid {
    background-image: url(../assets/icons/checkmark.svg?external);
    background-repeat: no-repeat;
    background-position: right;
    background-position-x: 95%;
    background-size: 15px;
  }

  z-index: 1;
  width: 257px;
  margin-bottom: 1em;
  @include bp-sm-phone-landscape {
    width: 500px;
    margin-bottom: 0;
  }
}
.logo {
  display: block;
  width: 2.5em;
  margin-top: 2em;
  margin-right: auto;
  margin-left: auto;
}
.checkbox {
  .communications {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
  .label-holder {
    margin-top: 1em;
  }

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 15.75em;
  margin-top: 1.5em;
  @include bp-sm-phone-landscape {
    width: 31.5em;
    margin-top: 2em;
  }
}
::v-deep .g-checkbox .label.after {
  margin-left: 1em;
  font-size: 18px;
  text-align: left;
  letter-spacing: -0.27px;
}
::v-deep .g-checkbox {
  display: flex;
  align-items: flex-start;
}
::v-deep .passport-checkbox .checkbox {
  margin-top: 6px;
}
.saved-settings {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  background-color: $primary-color;
}
.saved-text {
  margin-left: 1em;
  font-size: 16px;
}
.x-icon {
  display: flex;
  width: 20px;
  margin-right: 1em;
}
</style>
